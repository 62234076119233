import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "electronic-journal"
    }}>{`Electronic Journal`}</h1>
    <p>{`As mentioned in the previous section, the electronic journal is part of the economic logging registry of sales required by law, The Cash Register Systems Regulations, i.e. in Norwegian "kassasystemforskriften".  (`}<a parentName="p" {...{
        "href": "https://lovdata.no/dokument/SFE/forskrift/2015-12-18-1616"
      }}>{`https://lovdata.no/dokument/SFE/forskrift/2015-12-18-1616`}</a>{`)`}</p>
    <p>{`Whenever cash switches owner a transaction in the economic journal is required to be registered. All cash handling entities, either humans or machines are required by law to register the operation of handling of cash as a transaction in the economic journal. The same applies to making deposits from a cash storing device e.g. transfer of cash from a cashguard to a deposit location e.g. an IVA-machine.`}</p>
    <p>{`Sale not involving transfer of cash (e.g the sale of tickets through a web page or a mobile app) are not subjected to the Cash Register Systems Regulations and their economic report are handled by ordinary accounting systems.`}</p>
    <p>{`Important concepts:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Deposit`}</strong>{`: Moving money into or out of a settlement without transferring the money out of Entur. Examples are empyting CashGuard, refilling TVM, depositing money on an IVA machine etc.`}</li>
      <li parentName="ul"><strong parentName="li">{`Diff`}</strong>{`: A representation of a mismatch between the amount of money the settlement should contain and the amount it actually has`}</li>
      <li parentName="ul"><strong parentName="li">{`Economy`}</strong>{` report: The z-report for a settlement. The Z-report is the registry of transactions of cash in a cash handling device for one day.`}</li>
      <li parentName="ul"><strong parentName="li">{`Event`}</strong>{`: Used for logging events happening in the electronic journal`}</li>
      <li parentName="ul"><strong parentName="li">{`Receipt`}</strong>{`: A representation of a receipt for different kinds of transactions. All receipts have to be counted by the electronic journal`}</li>
      <li parentName="ul"><strong parentName="li">{`Payment`}</strong>{`: The full or partial payment of a transaction.`}</li>
      <li parentName="ul"><strong parentName="li">{`Point`}</strong>{` of sale: The place where a transaction is completed. For mobile terminals (MT) this is the employee using the terminal rather than a physical location`}</li>
      <li parentName="ul"><strong parentName="li">{`Settlement`}</strong>{`: An overview of the sales performed in a given period of time`}</li>
      <li parentName="ul"><strong parentName="li">{`Summary`}</strong>{`: A summary of transactions, deposits and diffs`}</li>
      <li parentName="ul"><strong parentName="li">{`Terminal`}</strong>{` report: A representation of the z-report produced by the terminal for a pos`}</li>
      <li parentName="ul"><strong parentName="li">{`Transaction`}</strong>{`: A transfer of money`}</li>
      <li parentName="ul"><strong parentName="li">{`Transaction`}</strong>{` line: A part of a transaction.`}</li>
    </ul>
    <p><img alt="ER Model" src={require("./ER-model.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      